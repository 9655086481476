import ImageCompressor from 'image-compressor.js'
import Store from '@/store/index'
import axios from 'axios'
import { Message } from 'element-ui'
import jsonpAdapter from 'axios-jsonp'
// 图片压缩
export function imageCompress(file, quality = 0.6) {
  return new Promise((resolve, reject) => {
    const options = {
      success(result) {
        // 将压缩后的 Blob 转换为 File 对象（如果组件支持Blob则不用这一步）
        const compressedFile = new File([result], file.name, {
          type: file.type,
          lastModified: Date.now(),
        })
        resolve(compressedFile)
      },
      error(e) {
        reject(e)
      },
    }
    if (file.size > 5 * 1024 * 1024) {
      options.quality = quality // 压缩质量
      options.convertSize = false //不进行图像尺寸的调整
      options.checkOrientation = false // 图片翻转，默认为false
    }
    new ImageCompressor(file, options)
  })
}

// 获取树节点所有祖先节点
export function getTreeNodeAncestor(arr, id, findProps = 'name', childProps = 'children') {
  const stock = []
  let going = true
  const recursion = (arr, id, findProps, childProps) => {
    arr.forEach(item => {
      if (!going) return
      stock.push(item)
      if (item[findProps] === id) {
        going = false
      } else if (item[childProps]) {
        recursion(item[childProps], id, findProps, childProps)
      } else {
        stock.pop()
      }
    })
    if (going) stock.pop()
  }
  recursion(arr, id, findProps, childProps)
  return stock
}

export function dataFormat(value, fmt = 'yyyy-MM-dd') {
  let getDate = value ? new Date(value) : new Date()
  let o = {
    'M+': getDate.getMonth() + 1,
    'd+': getDate.getDate(),
    'h+': getDate.getHours(),
    'm+': getDate.getMinutes(),
    's+': getDate.getSeconds(),
    'q+': Math.floor((getDate.getMonth() + 3) / 3),
    S: getDate.getMilliseconds(),
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (getDate.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return fmt
}

// 金额转换成大写
export function numToCn(money) {
  const cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖')
  // 基本单位
  const cnIntRadice = new Array('', '拾', '佰', '仟')
  // 对应整数部分扩展单位
  const cnIntUnits = new Array('', '万', '亿', '兆')
  // 对应小数部分单位
  const cnDecUnits = new Array('角', '分', '毫', '厘')
  // 整数金额时后面跟的字符
  const cnInteger = '整'
  // 整型完以后的单位
  const cnIntLast = '元'
  // 最大处理的数字
  const maxNum = 999999999999999.9999
  // 金额整数部分
  let integerNum
  // 金额小数部分
  let decimalNum
  // 输出的中文金额字符串
  let chineseStr = ''
  // 分离金额后用的数组，预定义
  let parts
  if (money == '') {
    return ''
  }
  money = parseFloat(money)
  if (money >= maxNum) {
    // 超出最大处理数字
    return ''
  }
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger
    return chineseStr
  }
  // 转换为字符串
  money = money.toString()
  if (money.indexOf('.') == -1) {
    integerNum = money
    decimalNum = ''
  } else {
    parts = money.split('.')
    integerNum = parts[0]
    decimalNum = parts[1].substr(0, 4)
  }
  // 获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    let zeroCount = 0
    let IntLen = integerNum.length
    for (let i = 0; i < IntLen; i++) {
      let n = integerNum.substr(i, 1)
      let p = IntLen - i - 1
      let q = p / 4
      let m = p % 4
      if (n == '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }
        // 归零
        zeroCount = 0
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q]
      }
    }
    chineseStr += cnIntLast
  }
  // 小数部分
  if (decimalNum != '') {
    let decLen = decimalNum.length
    let zeroCount = 0

    for (let i = 0; i < decLen; i++) {
      let n = decimalNum.substr(i, 1)
      if (n == '0') {
        zeroCount++
      } else if (n != '0') {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }
        // 归零
        zeroCount = 0
        chineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if (chineseStr == '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (decimalNum == '') {
    chineseStr += cnInteger
  }
  return chineseStr
}
export function numToCn1(money) {
  const cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖')
  // 基本单位
  const cnIntRadice = new Array('', '拾', '佰', '仟')
  // 对应整数部分扩展单位
  const cnIntUnits = new Array('', '万', '亿', '兆')
  // 对应小数部分单位
  const cnDecUnits = new Array('角', '分', '毫', '厘')
  // 整数金额时后面跟的字符
  const cnInteger = '整'
  // 整型完以后的单位
  const cnIntLast = '元'
  // 最大处理的数字
  const maxNum = 999999999999999.9999
  // 金额整数部分
  let integerNum
  // 金额小数部分
  let decimalNum
  // 输出的中文金额字符串
  let chineseStr = ''
  // 分离金额后用的数组，预定义
  let parts
  if (money === '' || money < 0) {
    return ''
  }
  money = parseFloat(money)
  if (money >= maxNum) {
    // 超出最大处理数字
    return ''
  }
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger
    return chineseStr
  }
  // 转换为字符串
  money = money.toString()
  if (money.indexOf('.') == -1) {
    integerNum = money
    decimalNum = ''
  } else {
    parts = money.split('.')
    integerNum = parts[0]
    decimalNum = parts[1].substr(0, 4)
  }
  // 获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    let zeroCount = 0
    let IntLen = integerNum.length
    for (let i = 0; i < IntLen; i++) {
      let n = integerNum.substr(i, 1)
      let p = IntLen - i - 1
      let q = p / 4
      let m = p % 4
      if (n == '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }
        // 归零
        zeroCount = 0
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q]
      }
    }
    chineseStr += cnIntLast
  }
  // 小数部分
  if (decimalNum != '') {
    let decLen = decimalNum.length
    for (let i = 0; i < decLen; i++) {
      let n = decimalNum.substr(i, 1)
      if (n != '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if (chineseStr == '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (decimalNum == '') {
    chineseStr += cnInteger
  }
  return chineseStr
}

// 打印PDF
export async function printPdf(api, params = {}, tag = '_parent') {
  const user = Store.getters.user || null
  const org = Store.getters.currentOrg
  const { data } = await axios({
    method: 'get',
    url: `${process.env.VUE_APP_BASE_API}${api}`,
    params: {
      ...params,
      token: user.token,
      org_code: org.code,
    },
    responseType: 'blob',
  })
  if (data.type === 'application/json') {
    const reader = new FileReader()
    reader.onload = event => {
      const result = JSON.parse(event.target.result)
      if (result.message) return Message.error(result.message)
    }
    reader.readAsText(data)
  } else {
    let PDF = new Blob([data], { type: 'application/pdf' })
    let url = URL.createObjectURL(PDF)
    // let printWindow = window.open(tag)
    // printWindow.location = url
    // printWindow.print()
    // 原地直接打印
    const printFrame = window.document.createElement('iframe')
    printFrame.src = url
    printFrame.id = 'printFrame'
    printFrame.style.display = 'none'
    const printEl = document.getElementById('printFrame')
    if (printEl) window.document.body.removeChild(printEl)
    window.document.body.appendChild(printFrame)
    printFrame.onload = () => {
      printFrame.contentWindow.print()
    }
  }
}

// 模版打印
export async function tpPrint(tpName, data, width = 1000, height = 600) {
  if (!printTP[tpName]) return
  var printWindow = window.open('', '_blank', `height=${height},width=${width}`)
  printWindow.document.write(printTP[tpName](data))
  printWindow.document.close()
  printWindow.print()
}

// 当前月的第一天和最后一天
export function getCurrentMonthRange() {
  const now = new Date()
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)
  const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0)

  function formatDate(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  return [formatDate(firstDayOfMonth), formatDate(lastDayOfMonth)]
}

// 获取jsonp数据

export function getLocalJsonp(url = 'http://127.0.0.1:8011') {
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      adapter: jsonpAdapter,
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function copyToClipboard(text) {
  var textarea = document.createElement('textarea')
  textarea.style.position = 'fixed'
  textarea.style.opacity = 0
  textarea.value = text
  document.body.appendChild(textarea)
  textarea.select()
  document.execCommand('copy')
  document.body.removeChild(textarea)
}

export function isObject(value) {
  return typeof value === 'object' && value !== null && !Array.isArray(value) && Object.prototype.toString.call(value) === '[object Object]'
}
//
export function sourceObjectUpdate(sourceObj, ...args) {
  if (!isObject(sourceObj)) return sourceObj
  const objKeys = Object.keys(sourceObj)
  const ars = []
  args.forEach(arg => {
    if (isObject(arg)) ars.push(arg)
  })
  ars.forEach(item => {
    objKeys.forEach(key => {
      if (item[key] !== undefined) sourceObj[key] = item[key]
    })
  })
}
