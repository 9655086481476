import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/views/layout/layout.vue'
import login from '@/views/login/login.vue'
import Store from '@/store/index'
import systemSet from './modules/systemSet'
import basicsDataSet from './modules/basicsDataSet'
import publicAccountManagement from './modules/publicAccountManagement'
import plan from './modules/plan'
import voucher from './modules/voucher'
import reportForms from './modules/reportForms'
import settlementReport from './modules/settlementReport'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录' },
    hide: true,
    component: login,
  },
  {
    path: '/',
    name: 'layout',
    meta: { title: '主页' },
    hide: true,
    component: layout,
    redirect: '/home',
    children: [],
  },
]
// 异步路由
export const asyncRoutes = [
  {
    path: '/home',
    name: 'home',
    meta: { title: '主页' },
    component: () => import('@/views/home/index.vue'),
  },
  voucher, // 凭证
  // plan, // 计划
  settlementReport, // 结算报表
  reportForms, // 业务报表
  basicsDataSet, // 基础资料设置
  publicAccountManagement, // 小程序管理
  systemSet, // 系统设置
]

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes,
  })

const router = createRouter()
// 路由跳转
router.beforeEach((to, from, next) => {
  // 判断是否有登录信息
  if (to.name !== 'login' && !Store.getters.isLogin) next({ name: 'login' })
  if (!from.name) next()
  if (to.name !== from.name) next()
})

// 重置路由
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
export default router
