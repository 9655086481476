<!-- ui -->
<template>
  <div class="layout">
    <!-- 左侧菜单 -->
    <div class="side_bar" v-show="showLayout">
      <SideBar class="sidebar_container" :expand="expandMenu"></SideBar>
    </div>
    <div class="main_container">
      <div class="topbar" v-show="showLayout">
        <!-- 顶部实现 -->
        <NavBar />
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
  import SideBar from './components/SideBar'
  import NavBar from './components/NavBar'
  export default {
    name: 'layout',
    data() {
      return {}
    },

    components: {
      SideBar,
      NavBar,
    },
    created() {},
    computed: {
      showLayout() {
        return this.$store.getters.showLayout
      },
      expandMenu() {
        return this.$store.getters.expandMenu
      },
      containerStyle() {
        return this.expandMenu ? { width: 'calc(100vw - 180px)' } : { width: 'calc(100vw - 50px)' }
      },
    },

    mounted() {},

    methods: {},
  }
</script>
<style scoped lang="scss">
  .layout {
    min-height: 100%;
    min-width: 1080px;
    display: flex;
    justify-content: space-between;
  }
  .topbar {
    background: linear-gradient(#fcfdfd, #f6f7f7);
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 9;
  }
  .main_container {
    position: relative;
    flex: 1;
    background: #e4e5e6;
    transition: width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    min-height: 100vh;
    min-width: 1200px;
    flex-shrink: 0;
    overflow: hidden;
  }
</style>
