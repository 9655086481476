export default {
  path: '/publicAccountManagement',
  name: 'PublicAccountManagement',
  meta: { title: '小程序管理' },
  redirect: '/',
  auth: 'booking',
  component: () => import('@/views/publicAccountManagement/Index.vue'),
  children: [
    {
      path: '/publicAccountManagement/appointmentManagement',
      name: 'AppointmentManagement',
      meta: { title: '预约管理' },
      auth: 'listMakeAppointment',
      component: () => import('@/views/publicAccountManagement/appointmentManagement/List.vue'),
    },
    // {
    //     path: '/publicAccountManagement/announcement',
    //     name: 'Announcement',
    //     meta: { title: '公告管理' },
    //     auth: 'announcement',
    //     component: () => import('@/views/publicAccountManagement/announcement/List.vue'),
    // },
    // {
    //     path: '/publicAccountManagement/banner',
    //     name: 'Banner',
    //     meta: { title: '轮播图管理' },
    //     auth: 'banner',
    //     component: () => import('@/views/publicAccountManagement/banner/List.vue'),
    // },
    {
      path: '/publicAccountManagement/appointmentRecord',
      name: 'AppointmentRecord',
      meta: { title: '预约记录' },
      auth: 'listHouseholdAppointment',
      component: () => import('@/views/publicAccountManagement/appointmentRecord/List.vue'),
    },
  ],
}
