export default {
  path: '/reportForms',
  name: 'reportForms',
  meta: { title: '业务报表' },
  redirect: '/',
  auth: 'reportForms',
  component: () => import('@/views/reportForms/index.vue'),
  children: [
    // {
    //   path: '/reportForms/reservationInventoryOfHouseHold',
    //   name: 'reservationInventoryOfHouseHold',
    //   meta: { title: '定购数分户清册表' },
    //   auth: 'reservationInventoryOfHouseHold',
    //   component: () => import('@/views/reportForms/reservationInventoryOfHouseHold'),
    // },
    // {
    //   path: '/reportForms/purchaseUnitDrySummary',
    //   name: 'purchaseUnitDrySummary',
    //   meta: { title: '各单位烘干汇总表' },
    //   auth: 'gramManagementOfficeDryingFee',
    //   component: () => import('@/views/reportForms/purchaseUnitDrySummary'),
    // },
    // {
    //   path: '/reportForms/townDrySummary',
    //   name: 'townDrySummary',
    //   meta: { title: '各镇烘干汇总表' },
    //   auth: 'summarizedByTown',
    //   component: () => import('@/views/reportForms/townDrySummary'),
    // },

    // {
    //   path: '/reportForms/villageHouseholdSettlementList',
    //   name: 'villageHouseholdSettlementList',
    //   meta: { title: '村农户结算清单' },
    //   auth: 'villageSettlementList',
    //   component: () => import('@/views/reportForms/villageHouseholdSettlementList'),
    // },
    // {
    //   path: '/reportForms/villageSettlementList',
    //   name: 'villageSettlementList',
    //   meta: { title: '村结算清单' },
    //   auth: 'getVillageSettlementList',
    //   component: () => import('@/views/reportForms/villageSettlementList'),
    // },

    // {
    //   path: '/reportForms/dryingFeeForms',
    //   name: 'dryingFeeForms',
    //   meta: { title: '烘干费预测' },
    //   auth: 'dryingFeeForms',
    //   component: () => import('@/views/reportForms/dryingFeeForms'),
    // },
    // {
    //   path: '/reportForms/unitGradeForms',
    //   name: 'unitGradeForms',
    //   meta: { title: '各粮管所等级汇总表' },
    //   auth: 'unitGradeForms',
    //   component: () => import('@/views/reportForms/unitGradeForms'),
    // },
    {
      path: '/reportForms/weightedAverageQuality',
      name: 'weightedAverageQuality',
      meta: { title: '加权平均质量报表' },
      auth: 'weightedAverageQuality',
      component: () => import('@/views/reportForms/weightedAverageQuality'),
    },

    {
      path: '/reportForms/orderCompletionSchedule',
      name: 'orderCompletionSchedule',
      meta: { title: '订单完成进度表', swiper: true },
      auth: 'purchaseProgress',
      component: () => import('@/views/reportForms/orderCompletionSchedule'),
    },
    {
      path: '/reportForms/orderCompletionScheduleTotal',
      name: 'orderCompletionScheduleTotal',
      meta: { title: '订单完成进度汇总' },
      auth: 'purchaseProgressTotal',
      component: () => import('@/views/reportForms/orderCompletionScheduleTotal'),
    },
    {
      path: '/reportForms/wjgAcquisitionProgressDetail',
      name: 'wjgAcquisitionProgressDetail',
      meta: { title: '晚粳谷收购进度表' },
      auth: 'getVillageSettlementList',
      component: () => import('@/views/reportForms/wjgAcquisitionProgressDetail'),
    },
    {
      path: '/reportForms/xmAcquisitionProgressDetail',
      name: 'xmAcquisitionProgressDetail',
      meta: { title: '小麦收购进度表' },
      auth: 'villageSettlementList',
      component: () => import('@/views/reportForms/xmAcquisitionProgressDetail'),
    },
    {
      path: '/reportForms/agcPurchaseGradeTotal',
      name: 'agcPurchaseGradeTotal',
      meta: { title: '收购粮食等级统计表' },
      auth: 'statisticsPurchasingGrainGrades',
      component: () => import('@/views/reportForms/agcPurchaseGradeTotal'),
    },
  ],
}
