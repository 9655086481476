<template>
  <div id="app">
    <keep-alive :include="['layout']">
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
  export default {
    created() {
      // 动态路由持久化
      this.$store.dispatch('initRoutes')
    },
  }
</script>
<style></style>
