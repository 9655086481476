<!-- 顶部tabs -->
<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="item in breadcrumb" :key="item.name">{{ item.meta.title }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
  import { getTreeNodeAncestor } from '@/tools/tools'
  export default {
    data() {
      return {
        breadcrumb: [],
      }
    },

    components: {},

    computed: {
      currentRouterName() {
        return this.$route.name
      },
      permissionRoutes() {
        return this.$store.getters.permissionRoutes
      },
    },
    watch: {
      currentRouterName: {
        immediate: true,
        handler(val) {
          this.breadcrumb = getTreeNodeAncestor(this.permissionRoutes, val)
        },
      },
    },
    mounted() {},

    methods: {},
  }
</script>
<style scoped lang="scss"></style>
