import axios from 'axios'
import { Message } from 'element-ui'
import Store from '@/store/index'
import Router from '@/router/index'
import packageJson from '../../package.json'
import { UPDATETIMEKEY, SUCCESSSTATECODE, TOKENFAILURESTATECODE } from '@/common/constant'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 20000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
})
// 检查是否需要更新
function checkUpdate() {
  const oldTime = Number(sessionStorage.getItem(UPDATETIMEKEY))
  const curTime = Date.now()
  if (oldTime && oldTime + 60 * 1000 > curTime) return
  fetch(`${window.location.origin}/versions.json`, { cache: 'no-cache' }).then(async res => {
    const { version, must, reset } = await res.json()
    sessionStorage.setItem(UPDATETIMEKEY, curTime)
    if (packageJson.version != version && must) {
      if (reset) localStorage.clear()
      return window.location.reload()
    } else if (packageJson.version != version) {
      const isConfirm = confirm('收购系统有可用更新，是否立即更新')
      if (isConfirm) {
        if (reset) localStorage.clear()
        window.location.reload()
      }
    }
  })
}
const getUser = () => {
  const user = Store.getters.user || null
  const org = Store.getters.currentOrg
  return user ? { token: user.token, org_code: org.code } : user
}
// 请求拦截器
service.interceptors.request.use(
  config => {
    if (getUser()) {
      if (!config.data) config.data = {}
      if (!config.params) config.params = {}
      if (config.method === 'post') Object.assign(config.data, getUser())
      if (config.method === 'get') Object.assign(config.params, getUser())
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  res => {
    if (res.data.code === TOKENFAILURESTATECODE) {
      Message.error('登录失效')
      Store.dispatch('logout') // token失效
      return Promise.reject()
    } else if (res.data.code !== SUCCESSSTATECODE) {
      Message.error(res.data.message) // 响应失败
      return Promise.reject(res.data.message)
    }
    checkUpdate() // 检查更新
    return res.data
  },
  error => {
    if (error.message) Message.error(error.message)
    return Promise.reject(error)
  }
)

export default service
