export default {
  path: '/voucher',
  name: 'voucher',
  meta: { title: '凭证' },
  redirect: '/',
  auth: 'voucher',
  component: () => import('@/views/voucher/index.vue'),
  children: [
    {
      path: '/voucher/purchaseVoucher',
      name: 'purchaseVoucher',
      meta: { title: '收购凭证', swiper: true },
      auth: 'listAcquisition',
      component: () => import('@/views/voucher/purchaseVoucher/list'),
    },
    {
      path: '/voucher/purchaseVoucherAdd/:code?',
      name: 'purchaseVoucherAdd',
      meta: { title: '新增收购凭证', swiper: true },
      auth: 'createAcquisition',
      hide: true,
      component: () => import('@/views/voucher/purchaseVoucher/add'),
    },
    {
      path: '/voucher/purchaseVoucherDetail/:id',
      name: 'purchaseVoucherDetail',
      meta: { title: '收购凭证详情' },
      auth: 'infoAcquisition',
      hide: true,
      component: () => import('@/views/voucher/purchaseVoucher/detail'),
    },
    {
      path: '/voucher/purchaseVoucherEdit/:id',
      name: 'purchaseVoucherEdit',
      meta: { title: '收购凭证编辑' },
      auth: 'updateAcquisition',
      hide: true,
      component: () => import('@/views/voucher/purchaseVoucher/edit'),
    },
    {
      path: '/voucher/purchaseVoucherSpecialEdit/:id',
      name: 'purchaseVoucherSpecialEdit',
      meta: { title: '收购凭证特殊编辑' },
      auth: 'specialEditorAcquisition',
      hide: true,
      component: () => import('@/views/voucher/purchaseVoucher/specialEdit'),
    },
    {
      path: '/voucher/statementVoucher',
      name: 'statementVoucher',
      meta: { title: '结算凭证', swiper: true },
      auth: 'listSettlement',
      component: () => import('@/views/voucher/statementVoucher/list'),
    },
    {
      path: '/voucher/statementVoucherAdd',
      name: 'statementVoucherAdd',
      meta: { title: '新增结算凭证', swiper: true },
      auth: 'createSettlement',
      hide: true,
      component: () => import('@/views/voucher/statementVoucher/add'),
    },
    {
      path: '/voucher/statementVoucherDetail/:id',
      name: 'statementVoucherDetail',
      meta: { title: '结算凭证详情' },
      auth: 'infoSettlement',
      hide: true,
      component: () => import('@/views/voucher/statementVoucher/detail'),
    },
    {
      path: '/voucher/statementVoucherEdit/:id',
      name: 'statementVoucherEdit',
      meta: { title: '结算凭证编辑' },
      auth: 'updateSettlement',
      hide: true,
      component: () => import('@/views/voucher/statementVoucher/edit'),
    },
  ],
}
