let Validator = {
  extractBirth: function (id) {
    // 身份证提取出生年月
    let re = null,
      split,
      year,
      month,
      day
    if (id.length === 15) {
      re = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/i
    } else {
      re = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/i
    }
    //split = re.match(id);
    split = id.match(re)
    year = split[2]
    month = split[3]
    day = split[4]
    if (year.length == 2) {
      year = '19' + year
    }
    return year + '-' + month + '-' + day
  },
  // 从身份证号码中取性别，F表示女  M表示男
  extractGender: function (idno) {
    return ['F', 'M'][parseInt(idno.charAt(idno.length === 15 ? 14 : 16)) % 2]
  },
  isAdult: function (birth) {
    let today = new Date()
    if (typeof birth === 'string') {
      birth = new Date(Date.parse(birth))
    }
    let ty = today.getFullYear(),
      tm = today.getMonth(),
      td = today.getDate(),
      by = birth.getFullYear(),
      bm = birth.getMonth(),
      bd = birth.getDate()
    return ty - by > 12 || (ty - by === 12 && (tm > bm || (tm === bm && td >= bd)))
  },
  isChild: function (birth) {
    // 2 - 12 岁
    let today = new Date()
    if (typeof birth === 'string') {
      birth = new Date(Date.parse(birth))
    }
    let ty = today.getFullYear(),
      tm = today.getMonth(),
      td = today.getDate(),
      by = birth.getFullYear(),
      bm = birth.getMonth(),
      bd = birth.getDate()
    if (ty - by > 12 || (ty - by === 12 && (tm > bm || (tm === bm && td >= bd)))) {
      return false
    }
    if (ty - by < 2 || (ty - by === 2 && (tm < bm || (tm === bm && td < bd)))) {
      return false
    }
    return true
  },
  isPhoneTel: function (value) {
    return /^((\d{3,4}-?\d{7,8})|(1[356789][0-9]{9}))$/.test(value)
  },
  isPhone: function (value) {
    return /^1[23456789][0-9]{9}$/.test(value)
  },
  requiredArr(val) {
    return !val || !val.length
  },
  requiredObj(val, id) {
    return !val || id ? !val[id] : !Object.keys(val).length
  },
  requiredAddress(val) {
    return !val.address_area || !val.address_area.length || !val.address_detail.trim()
  },
  requiredPrice(val) {
    return val.price === undefined || val.price_times === undefined
  },
  requiredPriceSelect(val) {
    return !val.price_select || !val.price_select.val || val.price_times === undefined
  },
  isPwd(val) {
    // '密码至少8位且必须包含大小写字母和数字'
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,}$/.test(val)
  },
  isIdNo: function (id) {
    // 身份证校验
    let num = id.toUpperCase()
    //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
    if (!/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num)) {
      //alert('输入的身份证号长度不对，或者号码不符合规定！\n15位号码应全为数字，18位号码末位可以为数字或X。');
      return false
    }
    //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
    //下面分别分析出生日期和校验位
    let len, re
    len = num.length
    if (len == 15) {
      re = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/i
      let arrSplit = num.match(re)

      //检查生日日期是否正确
      let dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4])
      let bGoodDay
      bGoodDay = dtmBirth.getYear() == Number(arrSplit[2]) && dtmBirth.getMonth() + 1 == Number(arrSplit[3]) && dtmBirth.getDate() == Number(arrSplit[4])
      if (!bGoodDay) {
        //alert('输入的身份证号里出生日期不对！');
        return false
      } else {
        //将15位身份证转成18位
        //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
        let arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
        let arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
        let nTemp = 0,
          i
        num = num.substr(0, 6) + '19' + num.substr(6, num.length - 6)
        for (i = 0; i < 17; i++) {
          nTemp += num.substr(i, 1) * arrInt[i]
        }
        num += arrCh[nTemp % 11]
        return true
      }
    }
    if (len == 18) {
      re = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/i
      let arrSplit = num.match(re)

      //检查生日日期是否正确
      let dtmBirth = new Date(arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4])
      let bGoodDay
      bGoodDay = dtmBirth.getFullYear() == Number(arrSplit[2]) && dtmBirth.getMonth() + 1 == Number(arrSplit[3]) && dtmBirth.getDate() == Number(arrSplit[4])
      if (!bGoodDay) {
        return false
      } else {
        //检验18位身份证的校验码是否正确。
        //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
        let valnum
        let arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
        let arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
        let nTemp = 0,
          i
        for (i = 0; i < 17; i++) {
          nTemp += num.substr(i, 1) * arrInt[i]
        }
        valnum = arrCh[nTemp % 11]
        if (valnum != num.substr(17, 1)) {
          return false
        }
        return true
      }
    }
    return false
  },
  isChinese(value) {
    return /^[\u4e00-\u9fa5]+$/.test(value)
  },
  isEmail(value) {
    return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i.test(value)
  },
  isDate(value) {
    // yyyy-mm-dd
    return /^(19\d{2}|2\d{3})-(0[1-9]|1[012])-(0?[1-9]|[12]\d|3[01])$/.test(value)
  },
  isMonth(value) {
    return /^(0[1-9]|1[012]|[1-9])$/.test(value)
  },
  isDay(value) {
    return /^(0?[1-9]|[12]\d|3[01])$/.test(value)
  },
  isNumber(value) {
    return typeof value === 'number' && !isNaN(value)
  },
  isFloatNumber(value) {
    //浮点数
    return /^(-?\d+)(\.\d+)?$/.test(value)
  },
  isPinyin(value) {
    return /[a-zA-Z]{1,20}/.test(value)
  },
}

module.exports = Validator
