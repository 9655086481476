<!-- 登录页 -->
<template>
  <div class="flex w100 h100 login-page">
    <div class="banner flex1"></div>
    <div class="login-right">
      <div class="card login-card">
        <div class="title">
          <span style="font-size: 20px">欢迎登录</span>
          <i class="splitter"></i>
          <span style="font-size: 12px; color: #999">您的协同工作伙伴</span>
        </div>
        <el-form :model="loginForm" :rules="rules" label-position="left" ref="formRef">
          <el-form-item prop="account">
            <el-input v-model="loginForm.account" placeholder="请输入账号" @keyup.enter.native="handleLogin"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input show-password v-model="loginForm.password" placeholder="请输入密码" @keyup.enter.native="handleLogin"></el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="primary" class="w100" @click="handleLogin" :loading="btnLoading">登录</el-button>
        </div>
      </div>
      <div style="text-align: center">
        <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #999; font-size: 15px">沪ICP备2024090125号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
  import md5 from 'md5'
  import { cloneDeep } from 'lodash'
  import { isPhone } from '@/tools/Validator'
  export default {
    data() {
      return {
        loginForm: {
          account: '', // 13346629088
          password: '', // 123456
          code: '',
        },
        btnLoading: false,
        rules: {
          account: {
            validator: (rule, value, callback) => {
              if (!value) return callback(new Error('账号不能为空'))
              if (!isPhone(value)) return callback(new Error('请输入正确的账号'))
              return callback()
            },
          },
          password: {
            validator: (rule, value, callback) => {
              if (!value) return callback(new Error('密码不能为空'))
              if (value.length < 6) return callback(new Error('密码不能少于6位'))
              return callback()
            },
          },
        },
      }
    },

    components: {},

    computed: {},
    created() {},
    mounted() {},

    methods: {
      async handleLogin() {
        try {
          this.btnLoading = true
          await this.$refs.formRef.validate()
          const params = cloneDeep(this.loginForm)
          params.password = md5(params.password)
          const { data } = await this.$axios.post('user/user/login', params)
          this.$store.dispatch('initUserInfo', data)
          this.$store.dispatch('initRoutes')
          this.$router.push({ name: 'layout' })
        } catch (error) {
          console.log(error)
        } finally {
          this.btnLoading = false
        }
      },
    },
  }
</script>
<style scoped lang="scss">
  .login-page {
    // background-color: rgba(#409eff, 0.2);
    background-color: rgba(#cfdbf4, 0.8);
    .banner {
      height: 100%;
      border-right: 1px solid #ccc;
      // background-color: #f4f6f9;
      background: url('@/assets/banner.png') no-repeat center;
      background-color: #cfdbf4;
    }
    .login-card {
      margin: 35vh 10px 0;
      width: 350px;
      padding: 15px 10px 20px;
      text-align: center;
      border-radius: 10px;
      .title {
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;
      }
    }
  }
  .login-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
