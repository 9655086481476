<!-- 侧边导航栏 -->
<template>
  <div class="side-bar">
    <div class="log">
      <img :src="logoUrl" class="cu" alt="" :style="logStyle" />
    </div>
    <div class="route-list">
      <el-scrollbar style="height: calc(100vh - 60px)" class="scrollbar-route">
        <el-menu :default-active="activeMenu" background-color="#545c64" text-color="#fff" active-text-color="#409eff" style="height: 100%; width: 100%">
          <div v-for="item in permissionRoutes" :key="item.name">
            <el-submenu :index="item.path" v-if="item.children && item.children.length">
              <template slot="title">{{ item.meta.title }}</template>
              <template v-for="child in item.children">
                <el-menu-item :index="child.path" @click="handleRoute(child)" :key="child.name" v-if="child.hide !== true">{{ child.meta.title }}</el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item :index="item.path" @click="handleRoute(item)" v-else-if="item.hide !== true">{{ item.meta.title }}</el-menu-item>
          </div>
        </el-menu>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      expand: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        logoUrl: require('@/assets/logo.png'),
        // activeMenu: '',
      }
    },

    components: {},

    computed: {
      permissionRoutes() {
        return this.$store.getters.permissionRoutes
      },
      logStyle() {
        return {
          width: this.expand ? '50px' : '30px',
          height: this.expand ? '50px' : '30px',
          transform: this.expand ? 'translateY(0)' : 'translateY(50%)',
        }
      },
      activeMenu() {
        return this.$route.path
      },
    },

    mounted() {},

    methods: {
      handleRoute(item) {
        if (this.$route.name !== item.name) this.$router.push({ name: item.name })
      },
    },
  }
</script>
<style scoped lang="scss">
  .side-bar {
    transition: width 0.3s;
    position: relative;
    width: 180px;
    overflow: hidden;
    .log {
      height: 60px;
      width: 100%;
      text-align: center;
      box-shadow: 0 8px 5px -5px #eee;
      & img {
        transition: all 0.3s;
      }
    }
    .route-list {
      // height: calc(100vh - 60px);
      background-color: #545c64;
    }
    .el-submenu .el-menu-item {
      min-width: unset;
    }
  }
  ::v-deep(.scrollbar-route) {
    .el-scrollbar__wrap {
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
</style>
