export default {
  path: '/systemSet',
  name: 'systemSet',
  meta: { title: '系统设置' },
  redirect: '/',
  auth: 'systemconfig',
  component: () => import('@/views/systemSet/index.vue'),
  children: [
    {
      path: '/systemSet/userSetting',
      name: 'userSetting',
      meta: { title: '用户管理' },
      auth: 'listAccount',
      component: () => import('@/views/systemSet/userSetting/list'),
    },
    {
      path: '/systemSet/roleSetting',
      name: 'roleSetting',
      meta: { title: '角色管理' },
      auth: 'listRole',
      component: () => import('@/views/systemSet/roleSetting/list'),
    },
    {
      path: '/systemSet/purchaseUnit',
      name: 'purchaseUnit',
      meta: { title: '收购单位' },
      auth: 'listAcquiringUnit',
      component: () => import('@/views/systemSet/purchaseUnit/list'),
    },
    // {
    //   path: '/systemSet/authoritySetting',
    //   name: 'authoritySetting',
    //   meta: { title: '权限管理' },
    //   component: () => import('@/views/systemSet/authoritySetting/list'),
    // },
  ],
}
