import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from './axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/common/index.scss'
import 'default-passive-events'
Vue.config.productionTip = false
Vue.prototype.$axios = Axios
Vue.use(ElementUI)
import * as filters from './tools/filters'
Object.keys(filters).forEach(key => {
  //遍历filters.js内的方法
  Vue.filter(key, filters[key])
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
