import Vue from 'vue'
import Vuex from 'vuex'
import { asyncRoutes } from '@/router/index'
import Router from '@/router/index'
import { resetRouter } from '@/router/index'
import { ROUTELOCALKEY, USERINFOKEY, ROLELOCALKEY, ORGLISTKEY, CURRENTORGKEY, DEFAULTGRAINTYPE, BUYDEFAULTDATE, SELLSIDESEARCHRECORDKEY } from '@/common/constant'
import Axios from '@/axios/index'
import { cloneDeep } from 'lodash'
import { dataFormat } from '@/tools/tools.js'
Vue.use(Vuex)
// 过滤路由
function filterRoute(routes, roleAuth) {
  const result = []
  for (let i = 0; i < routes.length; i++) {
    const item = routes[i]
    if (!item.auth || roleAuth.some(x => x.Identifier === item.auth)) {
      if (item.children && item.children.length) {
        item.children = filterRoute(item.children, roleAuth)
      }
      result.push(item)
    }
  }
  return result
}
export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem(USERINFOKEY)) || '',
    showLayout: true,
    expandMenu: true,
    openRoutes: [],
    permissionRoutes: JSON.parse(localStorage.getItem(ROUTELOCALKEY) || '[]'),
    roleInfo: JSON.parse(localStorage.getItem(ROLELOCALKEY) || '[]'),
    keepRoutes: ['statementVoucher', 'purchaseVoucher', 'voucher'],
    orgList: JSON.parse(localStorage.getItem(ORGLISTKEY) || '[]'),
    currentOrg: JSON.parse(localStorage.getItem(CURRENTORGKEY)) || '',
    defaultGrainType: JSON.parse(localStorage.getItem(DEFAULTGRAINTYPE)) || 1,
    buyDefaultDate: JSON.parse(localStorage.getItem(BUYDEFAULTDATE)) || '',
    sellSideSearchRecord: JSON.parse(localStorage.getItem(SELLSIDESEARCHRECORDKEY) || '{}'),
  },
  getters: {
    user: state => state.user,
    showLayout: state => state.showLayout,
    expandMenu: state => state.expandMenu,
    openRoutes: state => state.openRoutes,
    roleInfo: state => state.roleInfo,
    permissionRoutes: state => state.permissionRoutes || [],
    isAuth: state => auth => state.roleInfo.some(x => x.Identifier === auth), // 是否有权限
    isLogin: state => (state.user ? true : false),
    keepRoutes: state => state.keepRoutes,
    orgList: state => {
      return state.currentOrg?.is_head_office === 1 ? state.orgList : []
    },
    currentOrg: state => state.currentOrg,
    isHeadOffice: state => (state.currentOrg?.is_head_office === 1 ? true : false),
    defaultGrainType: state => state.defaultGrainType,
    sellSideSearchRecordList: state => {
      return dataFormat(new Date()) === state.sellSideSearchRecord?.date ? state.sellSideSearchRecord?.list : []
    },
    buyDefaultDate: state => {
      if (!state.buyDefaultDate) return dataFormat(new Date())
      return state.buyDefaultDate.saveDate !== dataFormat(new Date()) ? dataFormat(new Date()) : state.buyDefaultDate.defaultDate
    },
  },
  mutations: {
    setUser(state, val) {
      state.user = val
      localStorage.setItem(USERINFOKEY, JSON.stringify(val))
    },
    setShowLayout(state, val) {
      state.showLayout = val
    },
    setExpandMenu(state, val) {
      state.expandMenu = val
    },
    setOpenRoutes(state, item) {
      const cIndex = state.openRoutes.findIndex(x => x.name === item.name)
      if (cIndex !== -1) state.openRoutes.splice(cIndex, 1)
      state.openRoutes.push(item)
    },
    setPermissionRoutes(state, routes) {
      state.permissionRoutes = routes
      localStorage.setItem(ROUTELOCALKEY, JSON.stringify(routes))
      routes.forEach(route => Router.addRoute('layout', route))
    },
    setRoleInfo(state, data = []) {
      state.roleInfo = data
      localStorage.setItem(ROLELOCALKEY, JSON.stringify(data))
    },
    setOrgList(state, data = []) {
      state.orgList = data
      localStorage.setItem(ORGLISTKEY, JSON.stringify(data))
    },
    setCurrentOrg(state, data = '') {
      state.currentOrg = data
      localStorage.setItem(CURRENTORGKEY, JSON.stringify(data))
    },
    setDefaultGrainType(state, data = 1) {
      state.defaultGrainType = data
      localStorage.setItem(DEFAULTGRAINTYPE, JSON.stringify(data))
    },
    setBuyDefaultDate(state, data = '') {
      state.buyDefaultDate = {
        defaultDate: data,
        saveDate: dataFormat(new Date()),
      }
      localStorage.setItem(BUYDEFAULTDATE, JSON.stringify(state.buyDefaultDate))
    },
    setSellSideSearchRecord(state, data = '') {
      const currentDate = dataFormat(new Date())
      const currentList = this.getters.sellSideSearchRecordList
      if (currentList.some(x => x.value === data)) return
      let result = { date: currentDate, list: [{ value: data }, ...currentList] }
      if (state.sellSideSearchRecord?.date !== currentDate) {
        result = { date: currentDate, list: [{ value: data }] }
      }

      state.sellSideSearchRecord = result
      localStorage.setItem(SELLSIDESEARCHRECORDKEY, JSON.stringify(result))
    },
  },
  actions: {
    // 初始化动态路由
    async initRoutes({ commit, getters, dispatch }, load) {
      resetRouter()
      if (getters.user.token) await dispatch('getRoleAuth')
      const routes = load || asyncRoutes
      commit('setPermissionRoutes', filterRoute(cloneDeep(routes), getters.roleInfo))
    },
    // 退出登录
    logout({ commit, getters }) {
      Axios.post('/user/user/quit')
      commit('setUser', null)
      Router.replace({ name: 'login' })
    },
    // 获取角色权限
    async getRoleAuth({ commit }) {
      try {
        const { data } = await Axios.post('/user/Permission/permissionList', { type: 1 })
        commit('setRoleInfo', data)
      } catch (error) {}
    },
    // 初始化用户信息
    initUserInfo({ commit }, data = {}) {
      commit('setUser', data)
      commit('setOrgList', data.org)
      commit('setCurrentOrg', data.defalut_org)
    },
  },
  modules: {},
})
