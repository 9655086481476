<!-- 顶部 -->
<template>
  <div class="navbar">
    <div class="top">
      <NavTabs />
      <div class="flex-center">
        <cardReader />
        <currentOrg />
        <el-divider direction="vertical"></el-divider>
        <currentUser />
      </div>
    </div>
  </div>
</template>

<script>
  import NavTabs from './NavTabs.vue'
  export default {
    data() {
      return {}
    },

    components: {
      NavTabs,
      currentUser: () => import('./currentUser.vue'),
      currentOrg: () => import('./currentOrg.vue'),
      cardReader: () => import('./cardReader.vue'),
    },

    computed: {},

    mounted() {},

    methods: {},
  }
</script>
<style scoped lang="scss">
  .navbar {
    width: 100%;
    height: 60px;
    .top {
      height: 40px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
</style>
