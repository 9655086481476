import { isNumber } from '@/tools/Validator'
import { round } from 'mathjs'

function number_format(number, decimals = 2, prefix = '', errfix = '--') {
  let num = parseFloat(number)
  if (!isNumber(num)) return errfix
  const isNegative = num < 0 ? '-' : ''
  //是否负数
  if (isNegative) num = Math.abs(num)
  let c = round(num, decimals).toFixed(decimals)
  let result = ''
  let int = c.split('.')[0]
  let flot = c.split('.')[1] ? '.' + c.split('.')[1] : ''

  while (int.length > 3) {
    result = ',' + int.slice(-3) + result
    int = int.slice(0, int.length - 3)
  }

  return `${prefix}${isNegative}${int ?? ''}${result}${flot}`
}

function field_item(val) {
  return val || '--'
}
export {
  number_format, //通过此处导出方法
  field_item,
}
