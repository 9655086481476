export default {
  path: '/basicsDataSet',
  name: 'basicsDataSet',
  meta: { title: '基础资料配置' },
  redirect: '/',
  auth: 'dictionary',
  component: () => import('@/views/basicsDataSet/index.vue'),
  children: [
    {
      path: '/basicsDataSet/dryingCharge',
      name: 'dryingCharge',
      meta: { title: '烘干费' },
      auth: 'listDrying',
      component: () => import('@/views/basicsDataSet/dryingCharge/list'),
    },
    {
      path: '/basicsDataSet/townSet',
      name: 'townSet',
      meta: { title: '村镇管理' },
      auth: 'listTownvillage',
      component: () => import('@/views/basicsDataSet/townSet/list'),
    },
    {
      path: '/basicsDataSet/gradePrice',
      name: 'gradePrice',
      meta: { title: '等级收购价格' },
      auth: 'listGradeprice',
      component: () => import('@/views/basicsDataSet/gradePrice/list'),
    },
    {
      path: '/basicsDataSet/household',
      name: 'household',
      meta: { title: '出售方', swiper: true },
      auth: 'listHousehold',
      component: () => import('@/views/basicsDataSet/household/list'),
    },
    {
      path: '/basicsDataSet/otherSet',
      name: 'otherSet',
      meta: { title: '其他配置项' },
      auth: 'listSyndry',
      component: () => import('@/views/basicsDataSet/otherSet/list'),
    },
    // {
    //   path: '/basicsDataSet/acreYield',
    //   name: 'acreYield',
    //   meta: { title: '种植面积' },
    //   auth: 'listArea',
    //   component: () => import('@/views/basicsDataSet/acreYield/list'),
    // },
    {
      path: '/basicsDataSet/licenseplate',
      name: 'licenseplate',
      meta: { title: '车牌号' },
      auth: 'listLicensePlate',
      component: () => import('@/views/basicsDataSet/licenseplate/list'),
    },
    {
      path: '/basicsDataSet/discountRatio',
      name: 'discountRatio',
      meta: { title: '折扣比例' },
      auth: 'listDiscounts',
      component: () => import('@/views/basicsDataSet/discountRatio/list'),
    },
    {
      path: '/basicsDataSet/transferAccount',
      name: 'transferAccount',
      meta: { title: '转账账号' },
      auth: 'listTransfer',
      component: () => import('@/views/basicsDataSet/transferAccount/list'),
    },

    {
      path: '/basicsDataSet/participant',
      name: 'participant',
      meta: { title: '参与人' },
      auth: 'listParticipants',
      component: () => import('@/views/basicsDataSet/participant/list'),
    },
  ],
}
