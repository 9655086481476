// 域名
// export const BASEURL = 'http://graincollection.shangyangltd.com/api/'

// 用户信息的key
export const USERINFOKEY = 'cerealUserInfo'
// 角色信息的key
export const ROLELOCALKEY = 'csroleInfo'
// 系统检查更新时间的key
export const UPDATETIMEKEY = 'systemUpdateTime'

// 响应成功状态码
export const SUCCESSSTATECODE = 200
// token失效状态码
export const TOKENFAILURESTATECODE = 10002
// 动态路由存在本地的key
export const ROUTELOCALKEY = 'permissionRoutes'

// 组织列表的key
export const ORGLISTKEY = 'cerealOrgUnitList'
// 当前组织
export const CURRENTORGKEY = 'cerealCurrentUnit'

// 默认粮食种类
export const DEFAULTGRAINTYPE = 'cerealGrainType'

// 收购凭证默认日期
export const BUYDEFAULTDATE = 'cerealBuyDefaultDate'

// 出售方搜索记录的

export const SELLSIDESEARCHRECORDKEY = 'cerealSellSideSearchRecord'
